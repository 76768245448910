import React, { useState, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import api from "../../services/api";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// ICONS
import SpeedIcon from "@material-ui/icons/Speed";
import Icon, { ViewColumn } from "@material-ui/icons/";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import TodayIcon from "@material-ui/icons/Today";
import CallIcon from "@material-ui/icons/Call";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import AddIcon from "@material-ui/icons/Add";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ForumIcon from "@material-ui/icons/Forum";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from "@material-ui/icons/Send";
import MessageIcon from "@material-ui/icons/Message";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TimerIcon from "@material-ui/icons/Timer";

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { toast } from "react-toastify";

import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";
import { Table } from "@material-ui/core";
import { ChartsDate } from "./ChartsDate";
import { ChatsUser } from "./ChartsUser";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 240,
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  card1: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card3: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card4: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card5: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card6: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card7: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card8: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
  card9: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
    //backgroundColor: "#6D30EF",
    backgroundColor: theme.palette.primary.main,
    color: "#eee",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState(null);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("all");
  const [queues, setQueues] = useState([]);
  const [queue, setQueue] = useState("all");
  const [counters, setCounters] = useState({});
  const [hoursPerContact, setHoursPerContact] = useState(null);
  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [reportDateFrom, setReportDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [reportDateTo, setReportDateTo] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();
  const { finding } = useCompanies();
  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleHoursPerUserReport(contact_id, date_from, date_to, user_id, queue_id) {
    setLoading(true);
    if (isEmpty(date_to) || isEmpty(date_from)) {
      toast.error("Preencha todas as datas");
      setLoading(false);
      return;
    }

    const reportData = await api.get("/timeperuserreport/", {
      params: { contact_id,
         date_from: moment(date_from).format("YYYY-MM-DD"),
          date_to: moment(date_to).format("YYYY-MM-DD"),
          user_id,
          queue_id},
    });

    setHoursPerContact(reportData.data);
    setLoading(false);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      const today = moment().format("YYYY-MM-DD");
      const startDate = moment().subtract(period, 'days').format("YYYY-MM-DD");
      params = {
        date_from: startDate,
        date_to: today,
      }
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
      if (isEmpty(dateTo)) {
        toast.error("Preencha todas as datas");
        setLoading(false);
        return;
      }
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
      if (isEmpty(dateFrom)) {
        toast.error("Preencha todas as datas");
        setLoading(false);
        return;
      }
    }

    const data = await find(params);
    console.log(data);

    setCounters(data.dashboard);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    const contactData = await api.get("/contacts/list", {
      params: { companyId: companyId },
    });
    setContacts(contactData.data);

    const userData = await api.get("/users/list", {
      params: { companyId: companyId },
    });
    setUsers(userData.data);

    const queueData = await api.get("/queue", {
      params: { companyId: companyId },
    });
    setQueues(queueData.data);

    setLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, []);
  //let companyDueDate = localStorage.getItem("companyDueDate");
  //const companyDueDate = localStorage.getItem("companyDueDate").toString();
  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      console.log("🚀 Console Log : e", e);
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    // Cria uma duração a partir do número total de minutos
    const duration = moment.duration(minutes, 'minutes');
    
    // Extrai as horas e minutos da duração
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    // Formata o resultado como "HH[h] mm[m]"
    return `${hours}h ${mins}m`;
}


  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Inicial"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Data Final"
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12} sm={6} md={4}>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="period-selector-label">Período</InputLabel>
            <Select
              labelId="period-selector-label"
              id="period-selector"
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
            >
              <MenuItem value={0}>Nenhum selecionado</MenuItem>
              <MenuItem value={3}>Últimos 3 dias</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
            <FormHelperText>Selecione o período desejado</FormHelperText>
          </FormControl>
        </Grid>
      );
    }
  }

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} justifyContent="flex-end">
          {/* GRID DO VENCIMENTO */}
          {/* <Grid item xs={12} sm={6} md={3}>
            <CardCounter
              icon={<TodayIcon fontSize="inherit" />}
              title="Data Vencimento"
              value={companyDueDate}
              loading={loading}
            />
          </Grid> */}

          {/* ATENDIMENTOS PENDENTES */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card1}
              style={{ overflow: "hidden" }}
              elevation={4}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Atd. Pendentes
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {counters.pendingTickets}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <CallIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* ATENDIMENTOS ACONTECENDO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card2}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Atd. Acontecendo
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {counters.openTickets}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <HourglassEmptyIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* ATENDIMENTOS REALIZADOS */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card3}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Finalizados
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {counters.closedTickets}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <CheckCircleIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Tempo Total de Atendimento */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card4}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Tempo Total de Atendimento
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {formatTime(counters.totalSupportTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                <TimerIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* T.M. POR ATENDIMENTO */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card8}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Tempo Médio por Atendimento
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {formatTime(counters.avgSupportTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <AccessAlarmIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* T.M. DE ESPERA */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              className={classes.card9}
              style={{ overflow: "hidden" }}
              elevation={6}
            >
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography component="h3" variant="h6" paragraph>
                    Tempo Médio de Espera
                  </Typography>
                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {formatTime(counters.avgWaitTime)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TimerIcon
                    style={{
                      fontSize: 100,
                      color: "#FFFFFF",
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* FILTROS */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
              <Select
                labelId="period-selector-label"
                value={filterType}
                onChange={(e) => handleChangeFilterType(e.target.value)}
              >
                <MenuItem value={1}>Filtro por Data</MenuItem>
                <MenuItem value={2}>Filtro por Período</MenuItem>
              </Select>
              <FormHelperText>Selecione o período desejado</FormHelperText>
            </FormControl>
          </Grid>

          {renderFilters()}

          {/* BOTAO FILTRAR */}
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>

          {/* DASHBOARD ATENDIMENTOS HOJE */}
          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper}>
              <Chart />
            </Paper>
          </Grid>

          {/* USUARIOS ONLINE */}
          <Grid item xs={12}>
            {attendants.length ? (
              <TableAttendantsStatus
                attendants={attendants}
                loading={loading}
              />
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2}>
              <ChatsUser />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper2}>
              <ChartsDate />
            </Paper>
          </Grid>

          {/* RELATORIO DE TEMPO DE ATENDIMENTO */}
          <Grid xs={12}>
            <Typography
              variant="h6"
              color="primary"
              component="h2"
              gutterBottom
              style={{ paddingLeft: "1%" }}
            >
              Relatório de tempo de atendimento por cliente
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Contato"
              select
              value={contact}
              onChange={(e) => {
                setContact(e.target.value);
                setHoursPerContact(null);
              }}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {contacts.length > 0
                ? contacts.map((contact) => (
                    <MenuItem value={contact}>{contact.name}</MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Atendente"
              select
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
                setHoursPerContact(null);
              }}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              {users.length > 0
                ? users.map((user) => (
                    <MenuItem value={user}>{user.name}</MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Departamento"
              select
              value={queue}
              onChange={(e) => {
                setQueue(e.target.value);
                setHoursPerContact(null);
              }}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              {queues.length > 0
                ? queues.map((queue) => (
                    <MenuItem value={queue}>{queue.name}</MenuItem>
                  ))
                : null}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Data Inicial"
              type="date"
              value={reportDateFrom}
              onChange={(e) => {
                setReportDateFrom(e.target.value);

                setHoursPerContact(null);
              }}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="Data Final"
              type="date"
              value={reportDateTo}
              onChange={(e) => {
                setReportDateTo(e.target.value);
                setHoursPerContact(null);
              }}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* BOTAO BUSCAR */}
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() =>
                handleHoursPerUserReport(
                  contact.id,
                  reportDateFrom,
                  reportDateTo,
                  user.id,
                  queue.id
                )
              }
              variant="contained"
              color="primary"
            >
              Buscar
            </ButtonWithSpinner>
          </Grid>
          {hoursPerContact && hoursPerContact.totalTime ? (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contato</TableCell>
                      <TableCell>Horas</TableCell>
                      <TableCell>Minutos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell>
                        {hoursPerContact.totalTime.hours || "00"}H
                      </TableCell>
                      <TableCell>
                        {hoursPerContact.totalTime.minutes || "00"}m
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Contato</TableCell>
                      <TableCell>Horas</TableCell>
                      <TableCell>Minutos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>---</TableCell>
                      <TableCell>---</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;