import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import useSettings from "../../hooks/useSettings";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const UserModal = ({ open, onClose, userId, companyId }) => {
  const { update, getAll } = useSettings();

  const classes = useStyles();

  const initialState = {
    number: "",
    userId: "",
  };

  const { user: loggedInUser } = useContext(AuthContext);

  const [userObject, setUserObject] = useState({});
  const [users, setUsers] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      if (open) {
        try {
          const { data } = await api.get(`/users/${userId}`);
          setUserObject(data);
          setIsEditing(true);
        } catch (err) {
          toastError(err);
        }
      }
    };

    fetchUser();
  }, [userId, open]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.get("/users/list");
        setUsers(data);
      } catch (err) {
        toastError(err);
      }
    };

    fetchUsers();
  }, []);

  const handleClose = () => {
    onClose();
    setUserObject(null);
    setIsEditing(false);
  };

  const handleSaveUser = async (values) => {
    try {
      if (userId) {
        await api.put(`/contacts/${userObject.contact.id}`, {
          number: values.number,
        });
      } else {
        const contact = await api.post("/contacts", {
          name: userObject.name,
          number: values.number,
          email: userObject.email,
          companyId: companyId,
        });
        await api.put(`/users/${userObject.id}`, {
          contactId: contact.data.id,
        });
        const settingList = await getAll();
        let oldValue;
        settingList.forEach((e) => {
          if (e.key === "redirectTo") {
            oldValue = e.value;
          }
        });
        let newValue = oldValue ? JSON.parse(oldValue) : [];

        if (!newValue.includes(values.userId)) {
          newValue.push(values.userId);
        }

        newValue = "[" + newValue.toString() + "]";
        await update({
          key: "redirectTo",
          value: newValue,
        });
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={{
            name: userObject?.name || "",
            number: userObject?.contact?.number || "",
            userId: userObject?.id || "",
          }}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, handleChange }) => (
            <Form>
              <DialogContent dividers>
                {!isEditing ? (
                  <Can
                    role={loggedInUser.profile}
                    perform="user-modal:editProfile"
                    yes={() => (
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        className={classes.maxWidth}
                        fullWidth
                      >
                        <InputLabel>Usuário</InputLabel>
                        <Field
                          as={Select}
                          value={userObject}
                          onChange={(e) => setUserObject(e.target.value)}
                          label={"Usuário"}
                        >
                          <MenuItem value={""}>&nbsp;</MenuItem>
                          {users.map((user) => (
                            <MenuItem key={user.id} value={user}>
                              {user.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    )}
                  />
                ) : null}
                <Field
                  as={TextField}
                  name="number"
                  label="Número"
                  autoFocus
                  placeholder="5513912344321"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {userId
                    ? `${i18n.t("userModal.buttons.okEdit")}`
                    : `${i18n.t("userModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
