import React, { useState, useEffect } from "react";
import qs from "query-string";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputMask from "react-input-mask";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../../assets/logo.png";
import { i18n } from "../../translate/i18n";

import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";
const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        PLW
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = {
    name: "",
    email: "",
    phone: "",
    password: "",
    planId: 8,
    cep: "",
	  address: "",
    neighborhood: "",
    city: "",
    uf: "",
  };

  const [user] = useState(initialState);
  const dueDate = moment().add(15, "day").format();

  const handleSignUp = async (values) => {
    Object.assign(values, { recurrence: "MENSAL" });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: "t" });
    Object.assign(values, { campaignsEnabled: true });
    try {
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };

  const onBlurCep = (ev, setFieldValue) => {
    const { value } = ev.target;
    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setFieldValue("neighborhood", data.bairro);
        setFieldValue("address", data.logradouro);
        setFieldValue("city", data.localidade);
        setFieldValue("uf", data.uf);
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <center>
            <img
              style={{ margin: "0 auto", width: "70%" }}
              src={logo}
              alt="Whats"
            />
          </center>
        </div>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSignUp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, setFieldValue, isSubmitting }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    autoComplete="name"
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Nome"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="email"
                    label={i18n.t("signup.form.email")}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoComplete="email"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="cnpjOrCpf"
                    label={"CPF ou CNPJ"}
                    name="cnpjOrCpf"
                    error={touched.cnpjOrCpf && Boolean(errors.cnpjOrCpf)}
                    helperText={touched.cnpjOrCpf && errors.cnpjOrCpf}
                    autoComplete="cnpjOrCpf"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="cep"
                    label="CEP"
                    name="cep"
                    onBlur={(ev) => onBlurCep(ev, setFieldValue)}
                    error={touched.cep && Boolean(errors.cep)}
                    helperText={touched.cep && errors.cep}
                    autoComplete="cep"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="uf-selection">UF</InputLabel>
                  <Field
                    as={Select}
                    variant="outlined"
                    fullWidth
                    id="uf-selection"
                    label="UF"
                    name="uf"
                    required
                  >
                    <MenuItem value="AC">Acre (AC)</MenuItem>
                    <MenuItem value="AL">Alagoas (AL)</MenuItem>
                    <MenuItem value="AP">Amapá (AP)</MenuItem>
                    <MenuItem value="AM">Amazonas (AM)</MenuItem>
                    <MenuItem value="BA">Bahia (BA)</MenuItem>
                    <MenuItem value="CE">Ceará (CE)</MenuItem>
                    <MenuItem value="DF">Distrito Federal (DF)</MenuItem>
                    <MenuItem value="ES">Espírito Santo (ES)</MenuItem>
                    <MenuItem value="GO">Goiás (GO)</MenuItem>
                    <MenuItem value="MA">Maranhão (MA)</MenuItem>
                    <MenuItem value="MT">Mato Grosso (MT)</MenuItem>
                    <MenuItem value="MS">Mato Grosso do Sul (MS)</MenuItem>
                    <MenuItem value="MG">Minas Gerais (MG)</MenuItem>
                    <MenuItem value="PA">Pará (PA)</MenuItem>
                    <MenuItem value="PB">Paraíba (PB)</MenuItem>
                    <MenuItem value="PR">Paraná (PR)</MenuItem>
                    <MenuItem value="PE">Pernambuco (PE)</MenuItem>
                    <MenuItem value="PI">Piauí (PI)</MenuItem>
                    <MenuItem value="RJ">Rio de Janeiro (RJ)</MenuItem>
                    <MenuItem value="RN">Rio Grande do Norte (RN)</MenuItem>
                    <MenuItem value="RS">Rio Grande do Sul (RS)</MenuItem>
                    <MenuItem value="RO">Rondônia (RO)</MenuItem>
                    <MenuItem value="RR">Roraima (RR)</MenuItem>
                    <MenuItem value="SC">Santa Catarina (SC)</MenuItem>
                    <MenuItem value="SP">São Paulo (SP)</MenuItem>
                    <MenuItem value="SE">Sergipe (SE)</MenuItem>
                    <MenuItem value="TO">Tocantins (TO)</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="city"
                    label="Cidade"
                    name="city"
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                    autoComplete="city"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="neighborhood"
                    label="Bairro"
                    name="neighborhood"
                    error={touched.neighborhood && Boolean(errors.neighborhood)}
                    helperText={touched.neighborhood && errors.neighborhood}
                    autoComplete="neighborhood"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    id="address"
                    label="Endereço"
                    name="address"
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    autoComplete="address"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={InputMask}
                    mask="(99) 99999-9999"
                    variant="outlined"
                    fullWidth
                    id="phone"
                    name="phone"
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                    autoComplete="phone"
                    required
                  >
                    {({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        fullWidth
                        label="Telefone com (DDD)"
                        inputProps={{ maxLength: 11 }} // Definindo o limite de caracteres
                      />
                    )}
                  </Field>
                </Grid>{" "}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="password"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    label={i18n.t("signup.form.password")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    required
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18n.t("signup.buttons.submit")}
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="/login" variant="body2">
                    {i18n.t("signup.buttons.login")}
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={5}></Box>
    </Container>
  );
};

export default SignUp;
