import React, { useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  Grid,
  Container,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Formik, Form, FastField, FieldArray } from "formik";
import { isArray } from "lodash";
import NumberFormat from "react-number-format";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  control: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  buttonContainer: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
}));

function SchedulesForm(props) {
  const { initialValues, onSubmit, loading, labelSaveButton } = props;
  const classes = useStyles();

  const [schedules, setSchedules] = useState([
    {
      weekday: "Segunda-feira",
      weekdayEn: "monday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Terça-feira",
      weekdayEn: "tuesday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Quarta-feira",
      weekdayEn: "wednesday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Quinta-feira",
      weekdayEn: "thursday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Sexta-feira",
      weekdayEn: "friday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Sábado",
      weekdayEn: "saturday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
    {
      weekday: "Domingo",
      weekdayEn: "sunday",
      startTime1: "",
      endTime1: "",
      startTime2: "",
      endTime2: "",
      startTime3: "",
      endTime3: "",
    },
  ]);

  const [period, setPeriod] = useState(0);

  useEffect(() => {
    if (isArray(initialValues) && initialValues.length > 0) {
      setSchedules(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <Formik
      enableReinitialize
      className={classes.fullWidth}
      initialValues={{ schedules }}
      onSubmit={({ schedules }) => {
        if (period === 1) {
          schedules.forEach((schedules) => {
            schedules.startTime2 = schedules.startTime1;
            schedules.startTime3 = schedules.startTime1;
            schedules.endTime2 = schedules.endTime1;
            schedules.endTime3 = schedules.endTime1;
          });
        } else if (period === 2) {
          schedules.forEach((schedules) => {
            schedules.startTime3 = schedules.startTime2;
            schedules.endTime3 = schedules.endTime2;
          });
        }

        setTimeout(() => {
          handleSubmit(schedules);
        }, 500);
      }}
    >
      {({ values }) => (
        <Form className={classes.fullWidth}>
          <FieldArray
            name="schedules"
            render={(arrayHelpers) => (
              <Grid spacing={4} container>
                <Container>
                  <FastField
                    as={TextField}
                    select
                    label="Quantos períodos de trabalho deseja adicionar?"
                    name="periods"
                    variant="outlined"
                    style={{ marginRight: "3.2%", width: "92.6%" }}
                    margin="dense"
                    onChange={(e) => {
                      setPeriod(e.target.value);
                    }}
                  >
                    <MenuItem value={1}>1 Período de trabalho.</MenuItem>
                    <MenuItem value={2}>2 Períodos de trabalho.</MenuItem>
                    <MenuItem value={3}>3 Períodos de trabalho.</MenuItem>
                  </FastField>
                </Container>
                {values.schedules.map((item, index) => {
                  if (period === 3) {
                    return (
                      <Container>
                        <FastField
                          as={TextField}
                          label="Dia da Semana"
                          name={`schedules[${index}].weekday`}
                          disabled
                          variant="outlined"
                          style={{ marginRight: "3.2%", width: "20%" }}
                          margin="dense"
                        />
                        <FastField name={`schedules[${index}].startTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].startTime2`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime2`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].startTime3`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime3`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                      </Container>
                    );
                  } else if (period === 2) {
                    return (
                      <Container>
                        <FastField
                          as={TextField}
                          label="Dia da Semana"
                          name={`schedules[${index}].weekday`}
                          disabled
                          variant="outlined"
                          style={{ marginRight: "3.2%", width: "20%" }}
                          margin="dense"
                        />
                        <FastField name={`schedules[${index}].startTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].startTime2`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime2`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                      </Container>
                    );
                  } else if (period === 1) {
                    return (
                      <Container>
                        <FastField
                          as={TextField}
                          label="Dia da Semana"
                          name={`schedules[${index}].weekday`}
                          disabled
                          variant="outlined"
                          style={{ marginRight: "3.2%", width: "20%" }}
                          margin="dense"
                        />
                        <FastField name={`schedules[${index}].startTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Início"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "1%", width: "10%" }}
                            />
                          )}
                        </FastField>
                        <FastField name={`schedules[${index}].endTime1`}>
                          {({ field }) => (
                            <NumberFormat
                              label="Final"
                              {...field}
                              variant="outlined"
                              margin="dense"
                              customInput={TextField}
                              format="##:##"
                              style={{ marginRight: "3.2%", width: "10%" }}
                            />
                          )}
                        </FastField>
                      </Container>
                    );
                  } else {
                    return;
                  }
                })}
              </Grid>
            )}
          ></FieldArray>
          <div
            style={{ textAlign: "center", marginTop: "2%" }}
            className={classes.buttonContainer}
          >
            <ButtonWithSpinner
              loading={loading}
              type="submit"
              color="primary"
              variant="contained"
            >
              {labelSaveButton ?? "Salvar"}
            </ButtonWithSpinner>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SchedulesForm;
