import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import useSettings from "../../hooks/useSettings";
import api from "../../services/api";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import TableRowSkeleton from "../../components/TableRowSkeleton";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import UserContactModal from "../../components/UserContactModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

export const RedirectContacts = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingRedirectContacts, setLoadingRedirectContacts] = useState(false);
  const [redirectContacts, setRedirectContacts] = useState("disabled");
  const [loadingRedirectRandom, setLoadingRedirectRandom] = useState(false);
  const [redirectRandom, setRedirectRandom] = useState("disabled");
  const [selectedUser, setSelectedUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [userIdArray, setUserIdArray] = useState([]);
  const [deletingUser, setDeletingUser] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { update, getAll } = useSettings();

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
    findData();
    fetchUsers();
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  async function findData() {
    setLoading(true);
    try {
      const companyId = localStorage.getItem("companyId");
      const settingList = await getAll();
      settingList.forEach((e) => {
        if (e.key == "redirectContacts") {
          setRedirectContacts(e.value);
        }
        if (e.key == "redirectRandom") {
          setRedirectRandom(e.value);
        }
        if (e.key == "redirectTo") {
          setUserIdArray(JSON.parse(e.value));
        }
      });
    } catch (e) {
      toastError(e);
    }
    setLoading(false);
  }
  useEffect(() => {
    findData();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await api.get("/users/list");
      setUsers(data);
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRedirectContacts = async (value) => {
    setRedirectContacts(value);
    setLoadingRedirectContacts(true);
    await update({
      key: "redirectContacts",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingRedirectContacts(false);
  };

  const handleRedirectRandom = async (value) => {
    setRedirectRandom(value);
    setLoadingRedirectRandom(true);
    await update({
      key: "redirectRandom",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingRedirectRandom(false);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleDeleteUser = async (userId) => {
    try {
      const { data } = await api.get(`/users/${userId}`);
      await api.delete(`/contacts/${data.contact.id}`);

      const settingList = await getAll();
      let oldValue;
      settingList.forEach((e) => {
        if (e.key === "redirectTo") {
          oldValue = e.value;
        }
      });
      let newValue = oldValue ? JSON.parse(oldValue) : [];
      newValue = newValue.filter((userId) => userId !== data.id);
      newValue = "[" + newValue.toString() + "]";
      await update({
        key: "redirectTo",
        value: newValue,
      });

      toast.success(i18n.t("users.toasts.deleted"));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toastError(err.response.data.message);
      } else {
        toastError(err);
      }
    }
    setDeletingUser(null);
    findData();
    fetchUsers();
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingUser &&
          `Remover ${deletingUser.name} da sua lista de redirecionamento?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      >
        {"Este usuário não irá mais receber os contatos dos clientes"}
      </ConfirmationModal>
      <UserContactModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="form-dialog-title"
        userId={selectedUser && selectedUser.id}
      />
      <MainHeader>
        <Title>Redirecionamento de Contatos</Title>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Grid spacing={3} container>
          <Grid xs={12} sm={6} md={5} item>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="ratings-label">Habilitar/Desabilitar</InputLabel>
              <Select
                labelId="ratings-label"
                value={redirectContacts}
                onChange={async (e) => {
                  handleRedirectContacts(e.target.value);
                }}
              >
                <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
                <MenuItem value={"enabled"}>Habilitadas</MenuItem>
              </Select>
              <FormHelperText>
                {loadingRedirectContacts && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={5} item>
            <FormControl className={classes.selectContainer}>
              <InputLabel id="ratings-label">Enviar</InputLabel>
              <Select
                labelId="ratings-label"
                value={redirectRandom}
                onChange={async (e) => {
                  handleRedirectRandom(e.target.value);
                }}
              >
                <MenuItem value={"disabled"}>Para todos</MenuItem>
                <MenuItem value={"enabled"}>Aleatóriamente</MenuItem>
              </Select>
              <FormHelperText>
                {loadingRedirectRandom && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <MainHeaderButtonsWrapper>
            <Button
              style={{ marginTop: "1em", marginRight: "1em" }}
              variant="contained"
              color="primary"
              onClick={handleOpenUserModal}
            >
              Adicionar usuário
            </Button>
          </MainHeaderButtonsWrapper>
        </Grid>

        <Table size="small" style={{ marginTop: "1em" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("users.table.email")}
              </TableCell>
              <TableCell align="center">Telefone</TableCell>
              <TableCell align="center">
                {i18n.t("users.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {users.map((user) =>
                userIdArray.includes(user.id) && user.contactId ? (
                  <TableRow key={user.id}>
                    <TableCell align="center">{user.name}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.contact.number}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditUser(user)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true);
                          setDeletingUser(user);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};
