import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import useHelps from "../../hooks/useHelps";

const useStyles = makeStyles(theme => ({
  mainPaperContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 200px)',
  },
  mainPaper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  helpPaper: {
    position: 'relative',
    width: '100%',
    minHeight: '120px',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '340px',
  },
  paperHover: {
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: `0 0 8px`,
      color: theme.palette.primary.main,
    },
  },
  textTitle: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  textDescription: {
    maxHeight: '100px',
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none', 
    color: 'inherit', 
  },
}));

const HelpsBlog = () => {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const { list } = useHelps();

  useEffect(() => {
    async function fetchData() {
      const helps = await list();
      setRecords(helps);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHelpsBlog = () => {
    return (
      <div className={`${classes.mainPaper} ${classes.mainPaperContainer}`}>
        {records.length ? records.map((record, key) => (
          record.video === null && (
            <a href={record.link} target="_blank" rel="noopener noreferrer" className={`${classes.link}`}>
              <Paper key={key} className={`${classes.helpPaper} ${classes.paperHover}`}>
                <Typography variant="button" className={classes.textTitle}>
                  {record.title}
                </Typography>
                <Typography variant="caption" className={classes.textDescription}>
                  {record.description}
                </Typography>
              </Paper>
            </a>
          )
        )) : null}
      </div>
    );
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("helps.title")}</Title>
        <MainHeaderButtonsWrapper></MainHeaderButtonsWrapper>
      </MainHeader>
      <div className={classes.mainPaper}>
        {renderHelpsBlog()}
      </div>
    </MainContainer>
  );
};

export default HelpsBlog;
