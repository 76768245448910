import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  planDetails: {
    marginTop: theme.spacing(2),
  },
}));

const ChoosePlanModal = ({ open, onClose, companyId }) => {
  const classes = useStyles();

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [planDetails, setPlanDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/plans/public");
        setPlans(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchPlans();
    }
  }, [open]);

  const handlePlanChange = async (planId) => {
    setSelectedPlan(planId);
    if (planId) {
      try {
        const { data } = await api.get(`/plans/${planId}`); // Assumindo que essa rota retorna os detalhes do plano
        setPlanDetails(data);
      } catch (err) {
        toastError(err);
      }
    } else {
      setPlanDetails(null);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedPlan("");
    setPlanDetails(null);
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Contratar Solução</DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <FormControl variant="outlined" margin="dense" fullWidth>
              <InputLabel>Escolha um plano</InputLabel>
              <Select
                value={selectedPlan}
                onChange={(e) => handlePlanChange(e.target.value)}
                label={i18n.t("choosePlanModal.selectPlan")}
              >
                <MenuItem value="">&nbsp;</MenuItem>
                {plans.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>
                    {plan.name + " - " + " R$" + plan.value.toFixed(2)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {planDetails && (
            <div className={classes.planDetails}>
              <h3>Detalhes do Plano</h3>
              <p><strong>Valor:</strong> R${planDetails.value.toFixed(2)}</p>
              <p><strong>Usuários:</strong> {planDetails.users}</p>
              <p><strong>Conexões:</strong> {planDetails.connections}</p>
              <p><strong>Departamentos:</strong> {planDetails.queues}</p>
              <p><strong>Usar Agendas:</strong> {planDetails.useSchedules ? 'Sim' : 'Não'}</p>
              <p><strong>Usar Campanhas:</strong> {planDetails.useCampaigns ? 'Sim' : 'Não'}</p>
              <p><strong>Usar Chat Interno:</strong> {planDetails.useInternalChat ? 'Sim' : 'Não'}</p>
              <p><strong>Usar API Externa:</strong> {planDetails.useExternalApi ? 'Sim' : 'Não'}</p>
              <p><strong>Usar Kanban:</strong> {planDetails.useKanban ? 'Sim' : 'Não'}</p>
              <p><strong>Usar OpenAI:</strong> {planDetails.useOpenAi ? 'Sim' : 'Não'}</p>
              <p><strong>Usar Integrações:</strong> {planDetails.useIntegrations ? 'Sim' : 'Não'}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              try {
                const response = await api.request({
                  url: `/companies/${companyId}`,
                  method: 'GET',
                  data: { planId: planDetails.id }
                });
                const company = response.data;
            
                const newDueDate = moment(company.dueDate).add(1, 'month').format('YYYY-MM-DD');
                await api.request({
                  url: `/companies/${companyId}`,
                  method: 'PUT',
                  data: {planId: planDetails.id, dueDate: newDueDate}
                });
                await api.request({
                  url: `/inter-api/create-payment`,
                  method: 'POST',
                  data: {companyId}
                });

                toast.success("Plano escolhido com sucesso");
                handleClose();
                window.location.reload()
              } catch (err) {
                toastError(err);
              } finally {
                setLoading(false);
              }
            }}
            color="primary"
            variant="contained"
            className={classes.btnWrapper}
            disabled={loading || !selectedPlan}
          >
            Contratar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChoosePlanModal;
